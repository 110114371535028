import PropTypes from 'prop-types';
import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import orderBy from 'lodash.orderby';

import RefinemetList  from './refinementList/refinementList';
import ResultList     from './resultList/resultList';

import styles from './filter.module.css';

const searchClient = algoliasearch('2XUXUFIYNU', '3567886790ca157ec47d96e808d70a29');

const Filter = ({ className }) => (
  <div className={[styles.filter, className].join(' ')}>
    <InstantSearch styles={{border: '1px solid #000000'}} searchClient={searchClient} indexName="agent">
      <Configure
        hitsPerPage={21}
      />
      <RefinemetList
        className={styles.filterIndustry}
        title="In welcher Branche bist Du zuhause?"
        attribute="industry"
        transformItems={items => orderBy(items, "label", "asc")}
        limit={20}
        />
      <RefinemetList
        title="Welche Themen interessieren Dich?"
        attribute="theme"
        transformItems={items => orderBy(items, "label", "asc")}
        limit={20}
        />
      <ResultList />
    </InstantSearch>
  </div>
)

Filter.propTypes = {
  className: PropTypes.string
}

Filter.defaultProps = {
  className: ''
}

export default Filter;
