import PropTypes from 'prop-types';
import React from 'react';
import { connectInfiniteHits, PoweredBy } from 'react-instantsearch-dom';

import Card   from '../card/card';
import Button from '../../../atoms/button/button';

import styles from './resultList.module.css';

const ResultList = ({hits, hasMore, refineNext, className }) => (
  <>
    <div className={[styles.resultList, className].join(' ')}>
      {hits.map(hit => (
        <Card key={hit.objectID} hit={hit} />
      ))}
    </div>
    <Button className={styles.more} tagName="button" position="center" onClick={refineNext} disabled={!hasMore} >
      Weitere Vorschläge laden
    </Button>
    <PoweredBy className={styles.poweredBy} />
  </>
)

ResultList.propTypes = {
  className: PropTypes.string
}

ResultList.defaultProps = {
  className: ''
}

export default connectInfiniteHits(ResultList);
