import PropTypes from 'prop-types';
import React from 'react';
import truncate from 'lodash.truncate';

import Headline from '../../../atoms/headline/headline';
import Text     from '../../../atoms/text/text';

import styles from './card.module.css';

const OgImage = ({image}) => {
  return image ? <img className={styles.ogImage} src={image} alt="" /> : <div className={styles.ogImage}></div>
}

const Card = ({ hit }) => {
  return (
    <a className={styles.card} href={hit.url} target="_blank" rel="noopener noreferrer">
      <div className={styles.imgWrap}>
        <div className={styles.imgStage}>
          <OgImage image={hit.image} />
        </div>
      </div>
      <div className={styles.content}>
        <Headline className={styles.title} align="left" tag="h3">{hit.title}</Headline>
        <Text className={styles.description} tag="div">{truncate(hit.description, {'length': 120})}</Text>
      </div>
  </a>
  )
}

Card.propTypes = {
  hit: PropTypes.object,
  className: PropTypes.string
}

Card.defaultProps = {
  hit: {},
  className: ''
}

// export default RefinementList;

export default Card;
