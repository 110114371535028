import React        from 'react';
import { Helmet }   from 'react-helmet';
import { graphql }  from 'gatsby';

import Layout   from '../components/layout';

import Stage        from '../components/molecules/stage/stage';
import StageWelcome from '../components/molecules/stageWelcome/stageWelcome';
import StageKontakt from '../components/molecules/stageContact/stageContact';
import Filter       from '../components/organisms/filter/filter';

export default ({ data }) => (
  <Layout siteTitle="ChancenCheckin">
    <Helmet
      htmlAttributes={{
        lang: 'de'
      }}
    >
      <meta charSet="utf-8" />
      <title>ChancenCheckin</title>
      <meta name="description" content="Dein Geschäft befindet sich in der Krise? ChancenCheckin –  vielfältige Möglichkeiten für Deine unternehmerische Herausforderung. Schnell. Einfach. Individuell." />
      <link rel="canonical" href="https://www.chancencheckin.de" />
    </Helmet>
    <StageWelcome imgWelcome={data.imgWelcome.childImageSharp.fluid} />
    <Stage anchor="check">
      <Filter />
    </Stage>
    <StageKontakt />
  </Layout>
)

export const query = graphql`
  query {
    imgWelcome: file(relativePath: { eq: "stageWelcome/background.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
