import PropTypes from 'prop-types';
import React from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';

import Slugline from '../../../atoms/headline/slugline';

import styles from './refinementList.module.css';

const RefinementList = ({title, items, currentRefinement, refine, className }) => (
  <div className={[styles.refinementList, className].join(' ')}>
    <Slugline className={title} tag="h2">{title}</Slugline>
    <div className={styles.items}>
      {items.map(item => (
          <button
            key={item.label}
            href="/#"
            className={[styles.button, item.isRefined ? styles.active : ''].join(' ')}
            onClick={event => {
              event.preventDefault();
              refine(item.value);
            }}
          >
            {item.label}
            {/* ({item.count}) */}
          </button>
      ))}
    </div>
  </div>
)

RefinementList.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string
}

RefinementList.defaultProps = {
  title: '',
  className: ''
}

// export default RefinementList;

export default connectRefinementList(RefinementList);
