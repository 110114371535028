import React from 'react';

import Stage    from '../stage/stage';
import Headline from '../../atoms/headline/headline';
import Slugline from '../../atoms/headline/slugline';
import Text     from '../../atoms/text/text';
import Button   from '../../atoms/button/button';

import styles from './stageWelcome.module.css';

const StageWelcome = ({imgWelcome}) => (
  <Stage className={styles.stage} bgImage={imgWelcome} fullHeight={true} fullWidth={false} alignVert="middle">
    <Headline className={styles.headline}>
      <span className={styles.textBg}>Suchst Du neue Chancen</span>
      <br />
      <span className={styles.textBg}>für Dein Geschäft?</span>
    </Headline>
    <Slugline tag="h3" className={styles.slug}><span className={styles.textBg}>...und weißt nicht wo Du anfangen sollst?</span></Slugline>
    <Text className={styles.text} align="center">ChancenCheckin – vielfältige Möglichkeiten für Deine unternehmerische Herausforderung.
          Schnell. Einfach. Individuell.</Text>
    <Button href="/#check">Finde deine Chance!</Button>
  </Stage>
)
export default StageWelcome;
